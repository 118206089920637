import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js'

// https://dev.materialdesignicons.com/

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "plathion_logo.png" }) {
        childImageSharp {
          fixed(width: 50, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return <Img fixed={data.file.childImageSharp.fixed} aspectRatio />
}

const Header = ({ siteTitle }) => (
  <header className={'header'} >
    <div className="header-container">
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Image /> <span style={{ marginLeft: 5 }}  >PLATHION</span>
        </Link>
      </h1>
      <div className={'menu-top'}>
        <span className={'item-menu'} >Início</span>
        <span className={'item-menu'} >Serviços</span>
        <span className={'item-menu'} >Clientes</span>
        <span className={'item-menu'} >Contato</span>
      </div>
      <div>
        <Icon path={mdiMagnify} title="Pesquisar" size={1} />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
